import React from "react";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import Products from "./components/Products";
import Header from "./components/Header";

function App() {
  return (
    <Main>
      <Header />
      <Navbar />
      <Products />
    </Main>
  );
}

export default App;

const Main = styled.div`
  width: 70%;
  margin: auto;

  @media (max-width: 800px) {
    width: 90%;
  }
`;
