import React from "react";
import styled from "styled-components";
import useStore from "../store";
import categories from "../static/categories";
import Filters from "./Filters";

function Navbar() {
  const [selectedCategory, setSelectedCategory, setSelectedFilters] = useStore(
    (state) => [
      state.selectedCategory,
      state.setSelectedCategory,
      state.setSelectedFilters,
    ]
  );

  function setCategory(category) {
    setSelectedCategory(category);
    setSelectedFilters({});
  }

  return (
    <div>
      <Nav>
        {categories.map((category) => (
          <Category
            key={category.id}
            className="trimmed"
            selected={category.id === selectedCategory.id}
            onClick={() => setCategory(category)}
          >
            {category.name}
          </Category>
        ))}
      </Nav>
      <Filters />
    </div>
  );
}

export default Navbar;

const Nav = styled.nav`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const Category = styled.button`
  padding: 15px 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: ${({ selected }) => (selected ? "none" : "1px solid black")};
  color: ${({ selected }) => (selected ? "white" : "hsl(0, 0%, 20%)")};
  background-color: ${({ selected }) => (selected ? "#a23939" : "#f8f9fa")};
  &:hover,
  &:active {
    background-color: ${({ selected }) => (selected ? "#a23939" : "#e2e6ea")};
  }

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
