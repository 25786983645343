import client from "../client";

export function getFooter() {
  return client.getEntry("6WxIDmi2KnvWu2Kgrrb3uU");
}

export function getHeader() {
  return client.getEntry("1GbC1bRkAbWWegPprJ64to");
}

export function getAboutPage() {
  return client.getEntry("6ZsxwC0WOZqEFSy1ewhTEn");
}

export function getImagesPage() {
  return client.getEntry("5Yv5bRglXU1kqGdwLuMRWo");
}
