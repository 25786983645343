import _ from "lodash";

export function sort(array, by = "label") {
  return array.sort((a, b) => {
    const itemA = _.get(a, by)?.toString().toUpperCase();
    const itemB = _.get(b, by)?.toString().toUpperCase();

    if (itemA < itemB) return -1;
    if (itemA > itemB) return 1;
    return 0;
  });
}
