import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getFooter } from "../services/textService";

function Footer() {
  const [footer, setFooter] = useState(null);

  useEffect(() => {
    async function loadFooter() {
      const footerData = await getFooter();
      setFooter(footerData.fields.text);
    }
    loadFooter();
  }, []);

  const options = {
    renderText: (text) =>
      text
        .split("\n")
        .reduce(
          (children, textSegment, index) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),
  };

  return (
    <Wrapper>
      <FooterBox>{documentToReactComponents(footer, options)}</FooterBox>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
`;

const FooterBox = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 10vh;
  color: white;
  text-align: center;
  font-size: 2vw;

  @media (max-width: 800px) {
    padding: 6vh;
    font-size: 5vw;
  }
`;

export default Footer;
