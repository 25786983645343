import React from "react";
import ReactSelect from "react-select";
import l10n from "../../sv.json";

const Select = ({ name, value, options, onChange, label, error }) => {
  return (
    <div className="form-group w-100">
      {label && <label htmlFor={name}>{label}</label>}
      <ReactSelect
        id={name}
        name={name}
        options={options}
        placeholder={l10n[name]}
        value={value}
        noOptionsMessage={() => l10n.noMatch}
        onChange={(value) => onChange(name, value)}
        isClearable
        isSearchable
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
