import React, { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getHeader } from "../services/textService";

function Header() {
  const [header, setHeader] = useState(null);

  useEffect(() => {
    async function loadHeader() {
      const headerData = await getHeader();
      setHeader(headerData.fields.text);
    }
    loadHeader();
  }, []);

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => (
        <Link to={node.data.uri}>{node.content[0].value}</Link>
      ),
    },
    renderText: (text) =>
      text
        .split("\n")
        .reduce(
          (children, textSegment, index) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),
  };

  return (
    <>
      <Logo src="/skylt.jpg" />
      <Wrapper>{documentToReactComponents(header, options)}</Wrapper>
    </>
  );
}

export default Header;

const Wrapper = styled.div`
  width: 47%;
  margin: 40px 0;
  border-radius: 10px;
  font-size: 1vw;
  color: hsl(0, 0%, 50%);
  & > h1,
  h2,
  h3 {
    margin: 10px 0;
  }
  & a {
    color: #a23939;
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 30px);
    font-size: 2.2vw;
  }
`;

const Logo = styled.img`
  border-radius: 10px;
  width: 34vw;
  height: 17.5vw;
  position: absolute;
  top: 6%;
  left: 50.5%;

  @media (max-width: 800px) {
    position: initial;
    width: 100%;
    min-height: 320px;
    margin-bottom: 10px;
  }

  @media (max-width: 800px) {
    position: initial;
    width: 100%;
    min-height: 200px;
    margin-bottom: 5px;
  }
`;
