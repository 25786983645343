import { useEffect } from "react";
import useStore from "../store";

export default function useProducts() {
  const [
    selectedCategory,
    loadProducts,
    loadProductsForCategory,
  ] = useStore((state) => [
    state.selectedCategory,
    state.loadProducts,
    state.loadProductsForCategory,
  ]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    loadProductsForCategory(selectedCategory);
  }, [loadProductsForCategory, selectedCategory]);
}
