import React from "react";
import _ from "lodash";
import styled from "styled-components";
import useStore from "../store";
import Select from "./common/Select";
import { sort } from "../utils";

const filterOn = ["brand", "model", "type"];

function Filters() {
  const [
    searchQuery,
    setSearchQuery,
    products,
    selectedCategory,
    selectedFilters,
    setSelectedFilters,
  ] = useStore((state) => [
    state.searchQuery,
    state.setSearchQuery,
    state.products,
    state.selectedCategory,
    state.selectedFilters,
    state.setSelectedFilters,
  ]);

  const filterObj = selectedCategory.filters && createFilters();

  function onFilterSelect(name, value) {
    setSearchQuery("");
    setSelectedFilters(_.assign(selectedFilters, { [name]: value }));
  }

  function createFilters() {
    const result = {};

    selectedCategory.filters.forEach((filter) => {
      if (typeof filter === "object") {
        if (!filterOn.includes(filter.name)) return;
        result[filter.name] = sort(
          filter.data.map((item) => ({
            label: item,
            value: item,
          }))
        );
      } else {
        if (!filterOn.includes(filter)) return;
        result[filter] = sort(
          _.uniqBy(
            products.reduce((result, { fields, sys }) => {
              if (sys.contentType.sys.id === selectedCategory.id)
                result.push({
                  label: fields[filter],
                  value: fields[filter],
                });
              return result;
            }, []),
            "value"
          )
        );
      }
    });

    return result;
  }

  function resetSelectedFilters() {
    const resettedFilters = filterOn.reduce((acc, curr) => {
      acc[curr] = null;
      return acc;
    }, {});
    setSelectedFilters(resettedFilters);
  }

  function renderFilters() {
    return (
      filterObj && Object.keys(filterObj).map((filter) => renderSelect(filter))
    );
  }

  function search(value) {
    resetSelectedFilters();
    setSearchQuery(value);
  }

  function renderSelect(name) {
    return (
      <SelectWrapper key={name}>
        <Select
          name={name}
          options={filterObj[name]}
          onChange={onFilterSelect}
          value={selectedFilters[name]}
        />
      </SelectWrapper>
    );
  }

  return (
    <Wrapper>
      <SelectWrapper>
        <SearchBox
          placeholder="Sök"
          onChange={({ currentTarget }) => search(currentTarget.value)}
          value={searchQuery}
        />
      </SelectWrapper>
      {renderFilters()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const SelectWrapper = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const SearchBox = styled.input`
  box-sizing: border-box;
  width: 100%;
  line-height: 2.2;
  border-radius: 5px;
  outline: none;
  border: 1px solid hsl(0, 0%, 80%);
  color: hsl(0, 0%, 20%);
  padding-left: 10px;
  font-size: 16px;
  &:focus {
    border: 1px solid #a23939;
  }
`;

export default Filters;
