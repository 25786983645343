import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getImagesPage } from "./services/textService";
import { useHistory } from "react-router";

function ImagesPage() {
  const [imagesPage, setImagesPage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function loadImagesPage() {
      const imagesPageData = await getImagesPage();
      setImagesPage(imagesPageData.fields.text);
    }
    loadImagesPage();
  }, [imagesPage]);

  const options = {
    renderText: (text) =>
      text
        .split("\n")
        .reduce(
          (children, textSegment, index) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { url: imageUrl } = node.data.target.fields.file;
        return <img src={imageUrl} />;
      },
    },
  };

  return (
    <Wrapper>
      <FooterBox>
        {documentToReactComponents(imagesPage, options)}
        <Button onClick={() => history.goBack()}>Tillbaka</Button>
      </FooterBox>
    </Wrapper>
  );
}

export default ImagesPage;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterBox = styled.div`
  width: 70%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  text-align: center;
  padding: 10vh;
  color: white;
  & a {
    color: #a23939;
  }
`;

const Button = styled.button`
  padding: 15px 20px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  color: white;
  background-color: #a23939;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
