import create from "zustand";
import categories from "./static/categories";
import { getProducts } from "./services/productService";
import { sort } from "./utils";

const [useStore] = create((set) => ({
  allProducts: [],
  products: [],
  searchQuery: "",
  setSearchQuery: (query) => set({ searchQuery: query }),
  selectedCategory: categories[0],
  setSelectedCategory: (category) => set({ selectedCategory: category }),
  selectedFilters: {},
  setSelectedFilters: (filters) => set({ selectedFilters: filters }),
  loadProducts: async () => {
    const { items } = await getProducts();
    set({
      allProducts: items,
      products: getFilteredProducts(items, categories[0]),
    });
  },
  loadProductsForCategory: (category) => {
    set((state) => ({
      products: getFilteredProducts(state.allProducts, category),
    }));
  },
}));

function getFilteredProducts(products, category) {
  return sort(
    products.filter(
      (product) => product.sys.contentType.sys.id === category.id
    ),
    "fields.sortIndex"
  );
}

export default useStore;
