import React from "react";
import styled from "styled-components";

function Card({ product }) {
  if (!product.fields || !product.fields.image.fields) return null;

  const { image, title, price } = product.fields;
  const { title: imageTitle, file } = image.fields;

  return (
    <Column>
      <a href={file.url}>
        <Image src={file.url} alt={imageTitle} />
      </a>
      <Text>
        <Title>{title}</Title>
        <Price>{price}:-</Price>
      </Text>
    </Column>
  );
}

export default Card;

const Column = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 0.71vw;
  text-align: center;
  height: 320px;

  @media (max-width: 800px) {
    width: 40vw;
  }
`;

const Image = styled.img`
  width: 80%;
  height: 60%;
  margin-bottom: 20%;
  border-radius: 5px;

  @media (max-width: 800px) {
    margin-bottom: 10%;
  }
`;

const Text = styled.div`
  height: 20%;
`;

const Title = styled.div`
  height: 70%;
  text-align: center;
  overflow: hidden;
`;

const Price = styled.div`
  font-size: 20px;
  height: 30%;
`;
