import React from "react";
import _ from "lodash";
import styled from "styled-components";
import useProducts from "../hooks/useProducts";
import l10n from "../sv.json";
import useStore from "../store";
import Card from "./Card";
import Footer from "./Footer";

function Products() {
  const [searchQuery, products, selectedCategory, selectedFilters] = useStore(
    (state) => [
      state.searchQuery,
      state.products,
      state.selectedCategory,
      state.selectedFilters,
    ]
  );

  useProducts();

  const filteredProducts = products.filter((product) => {
    if (searchQuery) {
      return product.fields.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    } else {
      if (_.isEmpty(selectedFilters)) return true;
    }

    for (const filter in selectedFilters)
      if (
        selectedFilters[filter] &&
        selectedFilters[filter].value !== product.fields[filter]
      )
        return false;

    return true;
  });

  return (
    <>
      {filteredProducts.length === 0 && (
        <NoProductsWrapper>
          <NoProducts>{l10n.noProducts}</NoProducts>
        </NoProductsWrapper>
      )}
      <Row>
        {filteredProducts.map(
          (product) =>
            selectedCategory.id === product.sys.contentType.sys.id && (
              <Card key={product.sys.id} product={product} />
            )
        )}
      </Row>
      <Footer />
    </>
  );
}

export default Products;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-content: space-between;
  margin-top: 3vh;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const NoProductsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const NoProducts = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  padding: 10vh;
  color: white;
  text-align: center;
  font-size: 2vw;

  @media (max-width: 800px) {
    padding: 6vh;
    font-size: 7vw;
  }
`;
