import l10n from "../sv.json";

const filters = {
  rim: [
    "brand",
    "model",
    {
      name: "type",
      data: [l10n.summer, l10n.summerWinter],
    },
    "width",
    "inch",
    "et",
  ],
  tyre: [
    "brand",
    "model",
    {
      name: "type",
      data: [l10n.summer, l10n.winter, l10n.mudAndSnowShort],
    },
    "width",
    "profile",
    "inch",
  ],
};

const categories = [
  {
    id: "tyre",
    name: l10n["tyres"],
    filters: filters["tyre"],
  },
  {
    id: "rim",
    name: l10n["rims"],
    filters: filters["rim"],
  },
  { id: "accessory", name: l10n["accessories"] },
  {
    id: "secondhandtyres",
    name: l10n["secondHandTyres"],
    filters: filters["tyre"],
  },
];

export default categories;
