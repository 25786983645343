import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { getAboutPage } from "./services/textService";

function AboutPage() {
  const [aboutPage, setAboutPage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function loadAboutPage() {
      const aboutPageData = await getAboutPage();
      setAboutPage(aboutPageData.fields.text);
    }
    loadAboutPage();
  }, [aboutPage]);

  const options = {
    renderText: (text) =>
      text
        .split("\n")
        .reduce(
          (children, textSegment, index) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),
  };

  return (
    <Wrapper>
      <FooterBox>
        {documentToReactComponents(aboutPage, options)}
        <Button onClick={() => history.goBack()}>Tillbaka</Button>
      </FooterBox>
    </Wrapper>
  );
}

export default AboutPage;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterBox = styled.div`
  width: 70%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  text-align: center;
  padding: 10vh;
  color: white;
  & a {
    color: #a23939;
  }
`;

const Button = styled.button`
  padding: 15px 20px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  color: white;
  background-color: #a23939;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
